import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import GetStarted from "../components/sections/GetStarted"
import heroImg from '../images/antifraudsystem.png'


import pcfraud from '../images/hacker.svg'
import shield from '../images/shield-search.svg'
import group from '../images/group.svg'

import card from '../images/card-remove.svg'
import spy from '../images/spy.svg'


const Fraudsolution = () => {
  return (
    <Layout mainbg="grey">
        <SEO title={`Fraud solution - Digimeth.net`} />
        <section className="co-head typical__padding co-bg--grey">
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">Fraud</span>
                    <br />
                    <span className="default">Solution</span>
                  </h2>
                  <h3 className='description'>Detect and beat fraud — accept payments online safely!</h3>
                </div>
              </div>
            </div>

            <div className="right">
              <picture className="fluid__wrapper">
                <img alt="Integration" src={heroImg} />
              </picture>
            </div>
          </div>
        </section>



        <section className="typical__padding co-bg--white co-section">
          <div className="site__container grid">
            <div className="left">
              <div className="content--wrapper">
                <div className="content">
                  <h2>
                    <span className="colorized">Why do you need</span>
                    <br />
                    <span className="default">an anti-fraud system?</span>
                  </h2>
                </div>
              </div>
            </div>

            <div className="right">
             <p className='text'>
             If you are still wondering why should an online business use an anti-fraud system, you should remember that actual losses experienced by issuers, merchants, and acquirers all over the world exceed billions of dollars annually. Merchants are usually affected the most.
             </p>
            </div>
          </div>
        </section>


            <section className='co-bg--black typical__padding co-section'>
              <div className="site__container grid">
                <div className="left">
                  <div className="content--wrapper">
                    <div className="content">
                      <h2>
                        <span className="colorized">How does</span>
                        <br />
                        <span className="default white">the fraud occur?</span>
                      </h2>
                        <span className='description default white'>
                            However, industry players know how to cut those
                            <br/>
                            expenses and progress significantly in developing
                            <br/>
                            various systems designed to combat card fraud.
                        </span>
                    </div>
                  </div>
                </div>


                <div className='right'>
                    <picture className="fluid__wrapper">
                      <img src={pcfraud} alt="pc"/>
                    </picture>
                </div>
              </div>
              
              <div className='site__container'>
                <h3 className='colorized-title'>
                  <span className="colorized">Credit card fraud</span><span className="default white">{" "}can still happen for one 
of two reasons:</span>
                </h3>
              </div>

              <div className="site__container grid">
                <div className="half">
                  <div className='icon'>
                  <img src={card} alt="card" />
                  </div>
                  <p className='description medium white'>
                  The cardholder is not being honest. Which can be challenging to detect.
                  </p>
                </div>
                <div className="half">
             <div className='icon'>
             <img src={spy} alt="detection" />
                  </div>
                  <p className='description medium white'>
                  A criminal got hold of someone else’s credit card details.
                  </p>
                </div>
              </div>
            </section>

            <section className='co-bg--grey typical__padding co-section'>
            <div className='co-section--wrapper'>
              <div className="site__container grid">
                <div className="left">
                  <div className="content--wrapper">
                    <div className="content">
                      <h2>
                        <span className="colorized">Anti-fraud</span>
                        <br />
                        <span className="default">is what every online business should use!</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="site__container grid">
                <div className="half">
                  <div className='icon'>
                      <img src={group} alt="group" />
                  </div>
                  <h3 className="title">We analyze every transaction</h3>
                  <p>
                  The system evaluates the suspiciousness of financial transactions from the fraud point of view. The service reports on questionable actions' detection and recommendations for further processing. In short, it detects and prevents fraudulent transactions making your business safe for its customers, its partners, and itself.
                  </p>
                </div>
                <div className="half">
             <div className='icon'>
             <img src={shield} alt="shield" />
                  </div>
                  <h3 className="title">We use intelligent analysis in our algorithms.</h3>
                  <p>
                  Processing each payment through the fraud system allows the system to analyze the buyer's behavioral pattern. Digimeth stores and updates data on suspicious transactions to effectively and quickly determine the riskiness of a fraudulent transaction. Collected data allows the system to accurately determine the degree of risk of each payment and prevent it. Our expertise in fraud analytics and intelligent systems allows us to provide quality service.
                  </p>
                </div>
                </div>
              </div>
            </section>
        <GetStarted isGetStarted={true} title={false} description="The Digimeth system is customized for each business, considering all the subtleties and specifics"/>
    </Layout>
  )
}

export default Fraudsolution